import axios from "axios";
import { useEffect, useState } from "react";
import Login from "../login/Login";
import { Navigate, BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Core from "./Core";
import DataTable from "../dataTable/DataTable";
import Pagination from "react-bootstrap/Pagination";
import { Button, Table } from "react-bootstrap";
import CustomPagination from "../pagination/CustomPagination";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";


function Paciente() {

    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage] = useState(10);

    const [pacientes, setPacientes] = useState([]);
      // Get current data
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const [error, setError] = useState('');
    const [user, setUser] = useState(localStorage.getItem("user"));

    useEffect(() => {
        getPacientes();
        //{today.toLocaleDateString()} - {today.toLocaleTimeString()}
    }, []);


    const getPacientes = async () => {
        console.log('Buscando pacientes ');
        try{
            const response = await axios.get('https://dashboard.studiocorpoealma.com.br/api/v1/pacientes', 
            {
                headers : { 'Content-type' : 'application/json'}
            }
        );
        setPacientes(response.data.result);
        }catch (error) {
            if(!error?.response){
                setError('Erro ao acessar servidor');
            }
        }

    };
    const addPaciente = async () => {


    };
    


    return (
        <div>            
              
             <div class="d-inline"><h3 className="titPag">Pacientes</h3> <Button variant="success" onClick={addPaciente}>+ Novo Paciente</Button> </div>  
            <div class="container mt-5 mb-3">
            
            <Table striped bordered hover>
            <thead>
                     <tr>
                    <th>ID</th>
                    <th>Nome</th>
                    <th>E-mail</th>
                    <th>Celular</th>
                    <th>Data Matrícula</th>
                    <th>#</th>
                  </tr>
            </thead>
            <tbody>                            
            {pacientes?.map((item, index) => (
            <tr key={item.id_paciente}>
                <td>{item.id_paciente}</td>
                <td>{item.nome}</td>
                <td>{item.email}</td>
                <td>{item.celular}</td>
                <td>{item.data_matricula}</td>
                <td><Button variant="secondary" size="sm" onClick={addPaciente}><EditIcon /></Button> | <Button variant="danger" size="sm" onClick={addPaciente}><RemoveCircleOutlineIcon /></Button> </td>
            </tr>
            ))}
            </tbody>
          </Table>
          <CustomPagination
                        dataPerPage={dataPerPage}
                        totalData={pacientes?.length}
                        paginate={paginate}
                        currentPage={currentPage}
                    />


             </div>
        </div>
    );
  }

  export default Paciente;