
import React from 'react';import axios from "axios";
import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import Core from "./Core";

import 'bootstrap/dist/css/bootstrap.min.css';

function ModalAtendimento({ match })  {

    const [error, setError] = useState('');
    const [user, setUser] = useState(localStorage.getItem("user"));
    let { id } = useParams();


    return (
        <div>
            <h2 className='tit-pag'>Detalhes do atendimento</h2>
            <div class="container">
                <div class="row justify-content-md-center">
                    <p className='lbl-atendimento'><b>Aluno:</b> Julia Forato</p>
                    <p className='lbl-atendimento'><b>Data:</b> 29/06/2023 - 18:00hs</p>
                    <p className='lbl-atendimento'><b>Modalidade:</b> Pilates</p>
                </div>
                <div class="row justify-content-md-center">
                    <p className='lbl-atendimento'><b>Aparelho:</b> Chair</p>                
                </div>
                <div class="row justify-content-md-center">
                    <p className='lbl-atendimento'><b>Objetivo:</b> Trabalho de abdomem</p>
                </div>
            </div>
            <form >
                <div class="form-group form-check form-switch py-5">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                    <label class="form-check-label" for="exampleCheck1">Paciente não compareceu ao atendimento</label>                    
                </div>
                <button type="submit" class="btn btn-primary">Registrar falta</button>
            </form>
        </div>
    );
  }

  export default ModalAtendimento;