import { BrowserRouter as Router, Routes, Route, Link, useParams } from 'react-router-dom';
import { ProSidebarProvider } from "react-pro-sidebar";
import Login from './components/login/Login';
import logo from './logo.svg';
import './App.css';
import Agenda from './components/core/Agenda';
import Home from './components/core/Home';
import Core from './components/core/Core';
import ModalAtendimento from './components/core/ModalAtendimento';
import Paciente from './components/core/Paciente';

function App() {
  
  return (
    <ProSidebarProvider >
    <Router>
        <div className="App">
        <header className="App-header">
        </header>
      </div>
      <Routes >
        <Route path='/' element={<Core />} >
          <Route path='/' element={<Home />} />
          <Route path='/agenda' element={<Agenda />} />
          <Route path='/agenda/:id' element={<Agenda />} />
          <Route path='/atendimento/:id' element={<ModalAtendimento />} />
          <Route path='/pacientes' element={<Paciente />} />
        </Route>
        <Route path='/login' element={<Login />} />
      </Routes>
    </Router>
    </ProSidebarProvider>
  );
}

export default App;
