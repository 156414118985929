import axios from "axios";
import { useEffect, useState } from "react";
import Login from "../login/Login";
import { Navigate, BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Core from "./Core";

function Home() {

    const [error, setError] = useState('');
    const [user, setUser] = useState(localStorage.getItem("user"));

    const [idAtendimento, setIdAtendimento] = useState(null);
    const [dataAtendimento, setDataAtendimento] = useState(null);
    const [modalidadeAtendimento, setModalidadeAtendimento] = useState(null);
    const [aparelhoAtendimento, setAparelhoAtendimento] = useState(null);
    const [objetivoAtendimento, setObjetivoAtendimento] = useState(null);
    const [nomeAluno, setNomeAluno] = useState(null);
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);

    const [atendimentos, setAtendimentos] = useState(null);

    useEffect(() => {
        getAtendimento();
        //{today.toLocaleDateString()} - {today.toLocaleTimeString()}
    }, []);




    const getAtendimento = async () => {
        console.log('Buscando atendimento ');
        try{
            const response = await axios.get('https://dashboard.studiocorpoealma.com.br/api/v1/atendimentos-atuais', 
            {
                headers : { 'Content-type' : 'application/json'}
            }
        );
        setAtendimentos(response.data.result);
        }catch (error) {
            if(!error?.response){
                setError('Erro ao acessar servidor');
            }
        }

    };

    return (
        <div>
            <h3 className="titPag">Dashboard</h3>        
            <div class="container mb-3">
                <div class="row"> 
                    <h4 className="titDashboard">Atendimentos do horário atual</h4>           
                    { atendimentos?.map((item) => {
                    return <div class="col-md-4">  
                                <div class="card p-2 mb-2">
                                    <div class="card-header bg-success"><h5><b>{item.nome}</b></h5></div>
                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex flex-row align-items-center">                                    
                                            <div class="ms-2 mt-1 c-details">
                                            <span>Modalidade</span>
                                            </div>
                                        </div>
                                    <div className={item.css_modalidade} > <span> {item.nome_modalidade}</span> </div>
                                    </div>
                                    <div class="card-body">                                      
                                            <p className='card-text'><b>Aparelho:</b> {item.nome_aparelho}</p>                
                                            <p className='card-text'><b>Objetivo:</b> {item.objetivo}</p>
                                    </div>
                                    <div class="card-footer text-center">
                                        <div class="d-flex flex-row align-items-center">                                    
                                                <div class="ms-2 c-details">
                                                    <span><b>Profissional: {item.nome_profissional}</b></span>
                                                </div>
                                        </div>
                                    </div>      
                                </div>
                            </div>;
                }) }
                </div>
            </div>
        </div>
    );
  }

  export default Home;