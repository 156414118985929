import axios from "axios";
import './core.css';
import { useEffect, useState } from "react";
import Login from "../login/Login";
import { Navigate, Link, Outlet } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar  } from "react-pro-sidebar";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ReceiptRoundedIcon from "@mui/icons-material/ReceiptRounded";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import BubbleChartRoundedIcon from "@mui/icons-material/BubbleChartRounded";
import WalletRoundedIcon from "@mui/icons-material/WalletRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import SavingsRoundedIcon from "@mui/icons-material/SavingsRounded";
import MonetizationOnRoundedIcon from "@mui/icons-material/MonetizationOnRounded";
import SettingsApplicationsRoundedIcon from "@mui/icons-material/SettingsApplicationsRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import ShieldRoundedIcon from "@mui/icons-material/ShieldRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

function Core() {


    const [error, setError] = useState('');
    const [username, setUsername] = useState(localStorage.getItem("username"));
    const [user, setUser] = useState(localStorage.getItem("user"));
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } =
    useProSidebar();

    var meuInterval = null;
    useEffect(() => {
      meuInterval = setInterval(() => {
        getHours();
    }, 1000);

      }, []);

      const getHours = () => {
        const clock = document.getElementsByClassName('clock')[0]
        const date = new Date()
        const hours = date.getHours()
        const minutes = date.getMinutes()
        const seconds = date.getSeconds()
        const hour = hours < 10 ? `0${hours}` : hours
        const minute = minutes < 10 ? `0${minutes}` : minutes
        const second = seconds < 10 ? `0${seconds}` : seconds
        clock.innerHTML = `${hour}:${minute}:${second}`
      }

    const handleLogout = async (e) => {

        e.preventDefault();
        setUser(null);
        setError('');
        localStorage.removeItem("user");       
        <Navigate to="/login" replace={true} />
    }
    
    return (
        
       <div>
            {user == null ? (
                <Navigate to="/login" replace={true} />

        ) : (
           <div>
                <div>
                    <div style={{ display: "flex", height: "100vh" }}>
                    <Sidebar  className="sidebar">
                             <h1 className="bemvindouser">{username}</h1>                            
                        <Menu>
                            
                        <MenuItem
                            className="menu1"
                            icon={
                            <MenuRoundedIcon
                                onClick={() => {
                                collapseSidebar();
                                }}
                            />
                            }
                        >                           
                        </MenuItem>
                        <MenuItem icon={<GridViewRoundedIcon />} component={<Link to="/" className="link" />} > Dashboard </MenuItem>
                        <MenuItem icon={<CalendarMonthIcon />} component={<Link to="/agenda" className="link" />}> Agenda </MenuItem>
                        <MenuItem icon={<ReceiptRoundedIcon />} component={<Link to="/agenda" className="link" />}> Notas </MenuItem>                    
                        <SubMenu label="Cadastros" icon={<SettingsApplicationsRoundedIcon />}>
                            <MenuItem icon={<AccountCircleRoundedIcon />}> Usuários </MenuItem>
                            <MenuItem icon={<AccountCircleRoundedIcon />} component={<Link to="/pacientes" className="link" />}> Pacientes </MenuItem>
                            <MenuItem icon={<AccountCircleRoundedIcon />}> Profissionais </MenuItem>
                            <MenuItem icon={<AccountCircleRoundedIcon />}> Aparelhos </MenuItem>
                        </SubMenu>
                        <MenuItem icon={<LogoutRoundedIcon />} onClick={(e) => handleLogout(e)}> Logout </MenuItem>
                        </Menu>
                    </Sidebar>
                    <div className="content">
                        <Outlet />
                    </div>                
                    </div>
                </div>
                <div className="floatClock" >
                    <div class="clock"></div>
                </div>
           </div>
           
        )}
       </div>
    );
  }

  export default Core;