import axios from "axios";
import { useEffect, useState } from "react";
import Core from "../core/Core";
import { Navigate } from "react-router-dom";
import './login.css';
import Logo from '../../assets/images/logo.png';


function Login() {

    var meuInterval = null;
    useEffect(() => {
      meuInterval = setInterval(() => {
        getHours();
    }, 1000);

      }, []);

const [email, setEmail] = useState('');
const [senha, setSenha] = useState('');
const [error, setError] = useState('');
const [user, setUser] = useState(localStorage.getItem("user"));

    const handleLogin = async (e) => {
        e.preventDefault();
        console.log('Tentando efetuar login');
        try{
            const response = await axios.post('https://dashboard.studiocorpoealma.com.br/api/v1/login', 
            JSON.stringify({email, senha}),
            {
                headers : { 'Content-type' : 'application/json'}
            }
        );

        setUser(response.data);
        localStorage.setItem('user', user);
        localStorage.setItem('username', response.data.nome);
        
        }catch (error) {
            if(!error?.response){
                setError('Erro ao acessar servidor');
            }
            else if(error.response.status == 401) {
                setError('Usuário ou senha inválidos')
            }
        }

    };

    const handleLogout = async (e) => {
        e.preventDefault();
        setUser(null);
        setError('');
    }

    const getHours = () => {
        const clock = document.getElementsByClassName('clock')[0]
        const date = new Date()
        const hours = date.getHours()
        const minutes = date.getMinutes()
        const seconds = date.getSeconds()
        const hour = hours < 10 ? `0${hours}` : hours
        const minute = minutes < 10 ? `0${minutes}` : minutes
        const second = seconds < 10 ? `0${seconds}` : seconds
        clock.innerHTML = `${hour}:${minute}:${second}`
      }

    return (
      <div className='login-form-wrap'>
        {user == null ? (
                    <div>
                    <img className="logo-login" src={Logo} alt="Studio Corpo & Alma" />
                    <h4>Área Exclusiva</h4>
                    <form className='login-form'>
                      <input className="input-login" type='email' 
                             name='email' 
                             placeholder='Email' 
                             required 
                             onChange={(e) => setEmail(e.target.value)}
                       />
                      <input type='password' 
                             name='senha' 
                             placeholder='Senha' 
                             required 
                             className="input-login"
                             onChange={(e) => setSenha(e.target.value)}
                       />
                      <button type='submit' className='btn-login' onClick={(e) => handleLogin(e)}>Login</button>
                      <a className="esqueceuSenha" href="#">Esqueceu a senha?</a>
                    </form>
                    <p>{error}</p>
                    </div>

        ) : (
            <Navigate to="/" replace={true} />
        )}

        <div className="floatClock" >
            <div class="clock"></div>
        </div>

      </div>
    );
  }

  export default Login;