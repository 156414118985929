
import React from 'react';
import axios from "axios";
import { useEffect, useState } from "react";
import Login from "../login/Login";
import { Navigate, useParams } from "react-router-dom";
import Core from "./Core";
import Fullcalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import ptbrLocale from '@fullcalendar/core/locales/pt-br';
import ModalAtendimento from './ModalAtendimento';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Agenda() {


    const [error, setError] = useState('');
    const [user, setUser] = useState(localStorage.getItem("user"));

    const [show, setShow] = useState(false);
    const [idAtendimento, setIdAtendimento] = useState(null);
    const [dataAtendimento, setDataAtendimento] = useState(null);
    const [modalidadeAtendimento, setModalidadeAtendimento] = useState(null);
    const [aparelhoAtendimento, setAparelhoAtendimento] = useState(null);
    const [objetivoAtendimento, setObjetivoAtendimento] = useState(null);
    const [nomeAluno, setNomeAluno] = useState(null);


    function handleClose() {
        setIdAtendimento(null);
        setShow(false);
    }
    function handleShow(idAtendimento) {
        setIdAtendimento(idAtendimento);
        getAtendimento(idAtendimento);  
        setShow(true);
    }

    useEffect(() => {

    }, []);

    const events = [];

    const getAtendimentos = async () => {
        console.log('Buscando atendimentos');
        try{
            const response = await axios.get('https://dashboard.studiocorpoealma.com.br/api/v1/atendimentos', 
            {
                headers : { 'Content-type' : 'application/json'}
            }
        );

        return response.data.events;

        }catch (error) {
            if(!error?.response){
                setError('Erro ao acessar servidor');
            }
        }

    };

    const getAtendimento = async (id) => {
        console.log('Buscando atendimento ' + id);
        try{
            const response = await axios.get('https://dashboard.studiocorpoealma.com.br/api/v1/atendimentos/'+ id, 
            {
                headers : { 'Content-type' : 'application/json'}
            }
        );

        setDataAtendimento(new Date(new Date(Date.parse( response.data.result[0].data_hora_inicio)).getTime() - (new Date(Date.parse( response.data.result[0].data_hora_inicio)).getTimezoneOffset() * 60000)).toISOString().replace(/\..+/, '').replace("T", ' - '));
        setAparelhoAtendimento(response.data.result[0].nome_aparelho);
        setModalidadeAtendimento(response.data.result[0].nome_modalidade);
        setObjetivoAtendimento(response.data.result[0].objetivo);
        setNomeAluno(response.data.result[0].nome);

        }catch (error) {
            if(!error?.response){
                setError('Erro ao acessar servidor');
            }
        }

    };




    return (
        <div>
        <h3 className="titPag">Agenda</h3>
        <div className="fullCalendar">
            <Fullcalendar
            timeZone = 'America/Sao_Paulo'
            locale= { ptbrLocale }             
            navLinks = {true}
            editable = {true}
            plugins={
               [dayGridPlugin, timeGridPlugin, interactionPlugin, momentTimezonePlugin ] 
            }
            initialView={"timeGridWeek"}
            headerToolbar={
                {start: "today,prev,next",
                center: "title",
                 end: "dayGridMonth,timeGridWeek,timeGridDay"
                }
            }
            height={"85vh"}
            events = {getAtendimentos}
            eventClick =  {function(info){
                handleShow(info.event.id)
            } }

            />
        </div>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title><h2 className='tit-pag'>Detalhes do atendimento</h2></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>                    
                    <div class="container">
                        <div class="row justify-content-md-center">
                            <p className='lbl-atendimento'><b>Aluno:</b> {nomeAluno}</p>
                            <p className='lbl-atendimento'><b>Data:</b> {dataAtendimento}</p>
                            <p className='lbl-atendimento'><b>Modalidade:</b> {modalidadeAtendimento}</p>
                        </div>
                        <div class="row justify-content-md-center">
                            <p className='lbl-atendimento'><b>Aparelho:</b> {aparelhoAtendimento}</p>                
                        </div>
                        <div class="row justify-content-md-center">
                            <p className='lbl-atendimento'><b>Objetivo:</b> {objetivoAtendimento}</p>
                        </div>
                    </div>
                    <form >
                        <div class="form-group form-check form-switch py-5">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                            <label class="form-check-label" for="exampleCheck1">Paciente não compareceu ao atendimento</label>                    
                        </div>
                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Fechar
            </Button>
            <Button variant="success" onClick={handleClose}>
                Registrar falta
            </Button>
            </Modal.Footer>
        </Modal>
            
        </div>
    );
  }

  export default Agenda;